import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticated = connectedRouterRedirect({
    authenticatedSelector: state => state.admin.isLoggedIn && (window.localStorage.getItem('tokenCreatedDate') && (new Date().getTime() - window.localStorage.getItem('tokenCreatedDate'))/1000 <= 1800),
    wrapperDisplayName: 'UserIsAuthenticated',
    redirectPath: '/login'
});

export const userIsNotAuthenticated = connectedRouterRedirect({
    // Want to redirect the user when they are authenticated
    authenticatedSelector: state => !state.admin.isLoggedIn || !window.localStorage.getItem('tokenCreatedDate') || (window.localStorage.getItem('tokenCreatedDate') && (new Date().getTime() - window.localStorage.getItem('tokenCreatedDate'))/1000 >= 1800),
    wrapperDisplayName: 'UserIsNotAuthenticated',
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false
});