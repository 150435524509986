import axios from 'axios';
import { path } from './utils';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true
});


export const isSuccessStatusCode = (s) => {
    // May be string or number
    const statusType = typeof s;
    return (statusType === 'number' && s === 0) || (statusType === 'string' && s.toUpperCase() === 'OK');
};

const setupInterceptors = (history) => {
    instance.interceptors.response.use(response => {
        return response
    }, 
    error => {
        if (error.response.status === 401) {
            window.localStorage.clear();
            history.push(path.LOGIN)
        }
    })
}

export default setupInterceptors
